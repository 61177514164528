<template>
    <div class="w3-theme-l1 w3-row">
        <div class="w3-col l2 w3-text-blue">-&nbsp;</div>
        <div class="w3-col l8">
            <div class="w3-card-4 w3-margin-top w3-padding w3-round-large w3-theme-l3">
                <div align="center">
                    <h3>Data Sekolah</h3>                    
                </div>
                <form method="post" id="frmSekolah" v-on:submit.prevent="saveData" action="#" autocomplete="off">
                    <div class="w3-row" style="margin-top:5px;">
                        <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                            <label for="sekolah_singkatan">Kode Sekolah :</label>
                        </div>
                        <div class="w3-col l10 m9 s12 w3-small" style="position:relative">
                            <input title="Kode Sekolah" type="text" class="w3-input w3-small w3-border w3-round-large w3-border" ref="sekolah_singkatan" id="sekolah_singkatan" placeholder="Kode Sekolah" v-model="rec.sekolah_singkatan" />
                        </div>
                    </div>
                    <div class="w3-row" style="margin-top:5px;">
                        <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                            <label for="sekolah_name">Nama Sekolah :</label>
                        </div>
                        <div class="w3-col l10 m9 s12 w3-small" style="position:relative">
                            <input title="Nama Sekolah" type="text" class="w3-input w3-small w3-border w3-round-large w3-border" ref="sekolah_name" id="sekolah_name" placeholder="Nama Sekolah" v-model="rec.sekolah_name" />
                        </div>
                    </div>
                    <div class="w3-row" style="margin-top:5px;">
                        <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                            <label for="sekolah_alamat">Alamat Sekolah :</label>
                        </div>
                        <div class="w3-col l10 m9 s12 w3-small" style="position:relative">
                            <textarea title="Alamat Sekolah" type="text" class="w3-input w3-small w3-border w3-round-large w3-border" ref="sekolah_alamat" id="sekolah_alamat" placeholder="Alamat Sekolah" v-model="rec.sekolah_alamat"></textarea>
                        </div>
                    </div>
                    <div class="w3-row" style="margin-top:5px;">
                        <div class="w3-col l2 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                            <label for="sekolah_telp">Telp Sekolah :</label>
                        </div>
                        <div class="w3-col l10 m9 s12 w3-small" style="position:relative">
                            <textarea title="Telp Sekolah" type="text" class="w3-input w3-small w3-border w3-round-large w3-border" ref="sekolah_telp" id="sekolah_telp" placeholder="Telp Sekolah" v-model="rec.sekolah_telp"></textarea>
                        </div>
                    </div>
                    <hr class="w3-card-4">
                    <button class="w3-btn w3-block w3-theme w3-round" type="submit">
                        <i class="fa fa-save"></i>
                        Save
                    </button>
                </form>
            </div>
        </div>
        <div class="w3-col l2 w3-text-blue">-&nbsp;</div>
    </div>
</template>
<script>
export default {
    name: "Dashboard",
    components: {},
    data() {
        return {
            "appName" : "",
            "rec": {}
        };
    },
    methods: {
        saveData(event){
            const self = this;
            self.apiPost("/brantas/sekolah/edit", self.rec, function(res){
                if(res.success ){
                    self.$toast.success("Data Sudah tersimpan");
                }
            }, function(err){
                self.$toast.error(err);
            });
            event.preventDefault();
            return false;
        }
    },
    watch: {},
    mounted() {
        const self = this;
        self.apiPost("/brantas/sekolah/search", {
            operator: "AND",
            kondisi: "=",
            field: "sekolah_code",
            fieldvalue: "c4ca4238a0b923820dcc509a6f75849b",
            limit: 1,
            offset: 0
        }, function(res){
            if(res.success && res.ttlrec > 0){
                self.rec = res.rec[0];
            }
        }, function(err){
            self.$toast.error(err);
        });
    }
};
</script>